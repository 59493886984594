.question-details-page{
    width: calc( 100% - 300px - 24px);
    float: left;
    margin: 25px 0px;
    padding: 0px;
}

.question-details-container{
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.112);
}
.question-details-container-2{
    display: flex;
}
.question-votes{
    padding: 5px 20px 5px 10px;
}
.question-votes p{
    margin: 0%;
    font-size: 25px;
    text-align: center;
}
.votes-icon{
    font-size: 40px;
    cursor: pointer;
    color: rgb(206, 203, 203);
}
.votes-icon:active{
    color: #ef8236;
}
.question-details-container .question-body{
    line-height: 22px;
    white-space: pre-line;
}

.question-details-container .question-details-tags{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.question-details-container .question-details-tags p,
.post-ans-container p .ans-tags{
    padding: 5px 5px;
    margin: 3px;
    font-size: 13px;
    border-radius: 2px;
    background-color: #e1ecf4;
    color: #39739d;
    text-decoration: none;
    line-height: 22px;
}
.question-actions-user{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.question-actions-user button, .edit-question-btn{
    background-color: transparent;
    border: none;
    padding: 5px 0px;
    margin: 0px 10px 0px 0px;
    text-decoration: none;
    color: #939292;
    cursor: pointer;
    font-size: 14px;
    transition: 0.3s;
}
.question-actions-user button:active{
    border-bottom: solid 2px black;
}
.question-actions-user div:nth-child(2) p, 
.question-actions-user div:nth-child(2) .user-link{
    text-decoration: none;
    font-size: 14px;
    margin: 0%;
}
.user-link{
    display: flex;
    align-items: center;
}
.user-link div{
    padding-left: 10px;
}
/* post answer container */

.post-ans-container form textarea{
    padding: 10px;
    border: solid 1px rgba(0, 0, 0, 0.3);
    font-family: 'Roboto', sans-serif;
    width: calc(100% - 20px);
    resize: vertical;
}

.post-ans-container form .post-ans-btn{
    margin: 20px 0px;
    padding: 10px 10px;
    background-color: #009dff;
    color: white;
    border: solid 1px #009dff;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.5s all;
}

.post-ans-container form .post-ans-btn:hover{
    background-color: #0086d8;
}

/* Display answer container */

.display-ans{
    padding-bottom: 20px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.112);
}

.display-ans p{
    font-size: 14px;
    line-height: 18px;
    white-space: pre-line;
}
.auth-section{
    min-height: 100vh;
    margin: 0% auto;
    background-color: #f1f2f3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-container-1{
    padding: 20px;
    margin-right: 30px;
}

.login-logo{
    padding: 20px 30px;
}

.auth-container-2{
    min-width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.auth-container-2 form{
    width: 100%;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    box-shadow: 0 10px 25px rgb(0 0 0 / 5%),
     0 20px 48px rgb(0 0 0 / 5%),
     0 1px 4px rgb(0 0 0 / 10%);
}

.auth-container-2 form label input{
    padding: 10px;
    width: calc( 100% - 30px );
    border: solid 1px #0000003e;
    font-size: 13px;
}

.auth-container-2 form label:nth-child(1) h4,
.auth-container-2 form label:nth-child(2) h4,
.auth-container-2 form label:nth-child(3) h4{
    margin-bottom: 5px;
    margin-top: 10px;
}

.auth-container-2 form label:nth-child(4){
    display: flex;
}

.auth-container-2 form label:nth-child(4) input{
    width: 15%;
    margin: 13px 0px;
}

.auth-btn{
    margin-top: 10px;
    padding: 10px 5px;
    background-color: #009dff;
    border: solid 1px #009dff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s;
    font-size: 13px;
    font-weight: 500;
}

.auth-btn:hover{
    background-color: #018ce3;
}

.handle-switch-btn{
    background-color: transparent;
    color: #007ac6;
    border: none;
    font-size: 13px;
    cursor: pointer;
}